import { confirmUserAccount, sendVerificationMail } from '../../services/user';
import { createSessionForThatCompany } from '../../services/company';
import router from '../../router';
import LogRocket from 'logrocket';
// import Growlytics from "growlytics-browser"

export default {
  name: 'login',
  data() {
    return {
      successMsg: '',
      loading: false,
      emailVerificationPending: false
    };
  },
  methods: {
    sendVerificationMail() {
      this.loading = true;
      sendVerificationMail({})
        .then((result) => {
          this.successMsg = result.data.message;
          this.loading = false;
        })
        .catch((error) => {
          this.reportError(error);
          this.loading = false;
        });
    }
  },
  created() {
    if (this.$route.query.token) {
      let params = {
        token: this.$route.query.token
      };
      confirmUserAccount(params)
        .then((result) => {
          if (result.data.success) {
            this.$store.dispatch('setLoginStatus', true);

            this.$store.dispatch('setUserInfo', {
              userId: result.data.data.userId,
              first_name: result.data.data.first_name,
              last_name: result.data.data.last_name,
              email: result.data.data.email,
              newUser: result.data.data.newUser
            });

            // Identify user in logrocket
            let logrocketId = result.data.data.email + result.data.data.first_name + result.data.data.last_name;
            LogRocket.identify(logrocketId, {
              userId: result.data.data.userId,
              name: result.data.data.first_name + ' ' + result.data.data.last_name,
              email: result.data.data.email
            });

            // //Add customer info to growlytics.
            // Growlytics.identifyUser(result.data.data.userId, {
            //     name: result.data.data.first_name + ' ' + result.data.data.last_name,
            //     email: result.data.data.email
            // });

            if (result.data.data.companyList.length == 1) {
              // Redirect to our app if user has only one company
              let paramsBody = {
                companyId: result.data.data.companyList[0].id
              };
              createSessionForThatCompany(paramsBody)
                .then((result) => {
                  if (result.data.success) {
                    let userInfo = this.$store.state.token;

                    // important to update userId here
                    userInfo.userId = result.data.data.userId;
                    // updating selected company data in vue state
                    userInfo.companyCode = result.data.data.companyCode;
                    userInfo.companyId = result.data.data.companyId;
                    userInfo.role = result.data.data.role;
                    userInfo.hasProject = result.data.data.hasProject;
                    userInfo.projectUserList = [];
                    userInfo.apiRetentionDays = result.data.data.apiRetentionDays;
                    userInfo.sessionRetentionDays = result.data.data.sessionRetentionDays;
                    userInfo.errorRetentionDays = result.data.data.errorRetentionDays;
                    userInfo.errorTransactionsEnabled = result.data.data.errorTransactionsEnabled;

                    if (result.data.data.hasProject) {
                      let userInfo = this.$store.state.token;
                      userInfo.projectCode = result.data.data.projectCode ? result.data.data.projectCode : null;
                      userInfo.projectId = result.data.data.projectId ? result.data.data.projectId : null;
                      userInfo.projectName = result.data.data.projectName ? result.data.data.projectName : null;
                      userInfo.environment = result.data.data.environment !== null ? result.data.data.environment : null;
                      userInfo.project = result.data.data.project;

                      this.$store.dispatch('setUserInfo', userInfo);

                      if (result.data.data.projectCode && result.data.data.environment !== null) {
                        router.push({ name: 'Dashboard' });
                      } else {
                        router.push({ name: 'SelectProject' });
                      }
                    } else if (!result.data.data.hasProject && result.data.data.role == 'admin') {
                      // Allow creating projects if user role is admin
                      router.push({ name: 'CreateProject' });
                    } else {
                      this.errorToast(result.data.message);
                    }

                    this.selectedProject = userInfo.project;
                    this.$store.dispatch('setUserInfo', userInfo);
                  } else {
                    this.errorToast(result.data.message);
                  }
                })
                .catch((error) => {
                  this.reportError(error);
                  this.errorToast('Something is wrong, please contact support. Please try again later.');
                });
            } else {
              router.push({ name: 'SelectCompany' });
            }
            this.successToast('Email successfully verified.', 6000);
          } else {
            router.push({ name: 'SelectCompany' });
            this.successToast(result.data.message);
          }
        })
        .catch((error) => {
          this.errorToast('Something is wrong, please contact support. Please try again later.');
          router.push({ name: 'Login' });
          this.reportError(error);
        });
    }
  }
};
