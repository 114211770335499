<template>
  <div id="confirmEmailPage" class="row">
    <!-- LEFT IMAGE -->
    <div>
      <el-button @click="sendVerificationMail()">Resend</el-button>
    </div>
  </div>
</template>

<script>
import ConfirmEmailComponent from './confirmEmailComponent';
export default ConfirmEmailComponent;
</script>

<style lang="scss">
#confirmEmailPage {
  /* background: linear-gradient(rgba(255, 255, 255, 0.82), rgba(255, 255, 255, 0.57)), url(https://static.growlytics.in/dashboard-assets/assets/img/confirmEmail_bg.jpg); */
  background-size: cover;
  min-height: 100vh;
  overflow-x: hidden;

  #confirmEmailBox {
    width: 70%;
    min-height: 70vh;
    margin: auto;
    margin-top: 15vh;
    background: white;
    border-radius: 5px;

    .marketing {
      background-size: cover;
      border-radius: 5px 0px 0px 5px;
      padding: 20px;
      text-align: center;

      .header {
        font-size: 25px;
        color: #444;
      }

      .bannerImg {
        width: 80%;
        padding: 20px;
      }

      .points {
        color: #777;
        font-size: 12px;
        text-align: left;
        line-height: 30px;
      }
    }

    .confirmEmailContainer {
      padding-top: 0px;
      padding-bottom: 20px;
      border-left: 1px dashed #eee;

      .logoContainer {
        text-align: center;
        width: 100%;
        margin-top: 20px;
        font-size: 40px;

        .logoIcon {
          height: 60px;
        }
      }

      .confirmEmailHeader {
        font-size: 17px;
        text-align: center;
        margin-top: 10px;
        color: #555;
      }

      .errorDiv {
        padding-top: 20px;
        text-align: center;
        width: 100%;
        color: red;
      }

      .successDiv {
        padding-top: 20px;
        text-align: center;
        width: 100%;
        color: green;
      }

      .warnDiv {
        padding-top: 20px;
        text-align: center;
        width: 100%;
        color: blue;
      }

      .confirmEmailForm {
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 25px;

        .form-group {
          margin-bottom: 20px;

          label {
            color: #777;
            margin-bottom: -10px;
            font-size: 13px;
          }
        }

        .buttonContainer {
          text-align: center;
          padding-top: 10px;

          button {
            display: inline-block;
            width: 100px;
            font-weight: normal;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
